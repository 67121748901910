@use './color';
@use './typography';

@mixin button {
  @include typography.meta-regular;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 48px;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &.primary {
    color: color.$white;
    background: color.$brown-50;
    border: 1px solid color.$brown-50;

    &:active {
      background: color.$brown-40;
      border: 1px solid color.$brown-40;
    }

    &:disabled {
      color: color.$grey-30;
      background: color.$grey-40;
      border: 1px solid color.$grey-40;
    }

    &:focus {
      outline: 2px solid color.$black;
      outline-offset: 4px; // Add margin to the outline
    }
  }

  &.secondary {
    color: color.$brown-50;
    background: color.$white;
    border: 1px solid color.$brown-50;

    &:active {
      color: color.$brown-40;
      border: 1px solid color.$brown-40;
    }

    &:disabled {
      color: color.$grey-40;
      border: 1px solid color.$grey-40;
    }

    &:focus {
      outline: 2px solid color.$black;
      outline-offset: 4px; // Add margin to the outline
    }
  }

  &.tertiary {
    color: color.$brown-50;
    text-decoration: underline;
    display: inline-flex;
    width: auto;
    height: auto;

    &:active {
      color: color.$brown-40;
    }

    &:disabled {
      color: color.$grey-40;
    }

    &:focus {
      outline: 2px solid color.$black;
      outline-offset: 4px; // Add margin to the outline
    }
  }
}

button {
  @include button;

  & > i {
    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }

  &.primary {
    & > i {
      & > img {
        filter: color.$filter-white;
      }
    }
  }

  &.secondary {
    & > i {
      & > img {
        filter: color.$filter-brown-50;
      }
    }
  }
}

@use '../../ui/color';
@use '../../ui/typography';

/*
 * Standard select
 */

// Select
mat-form-field.mat-form-field-type-mat-select {
  & > div.mat-form-field-wrapper {
    margin: 0;

    & > div.mat-form-field-flex {
      padding-left: 16px;
      padding-right: 16px;
      margin-top: 0;

      & > div.mat-form-field-outline {
        display: none;
      }

      & > div.mat-form-field-infix {
        line-height: 48px;
        padding: 0;
        border-top: none;

        & > mat-select {
          &:before {
            content: '';
            position: absolute;
            left: -16px;
            top: 0;
            display: block;
            width: calc(100% + 32px);
            height: 48px;
            border: 1px solid color.$grey-70;
          }

          // Disabled
          &.mat-select-disabled {
            & > div.mat-select-trigger {
              & > div.mat-select-value {
                & > span.mat-select-value-text {
                  & > span.mat-select-min-line {
                    color: color.$grey-40;
                  }
                }
              }
            }
          }

          &[aria-expanded='true'] {
            &:before {
              border: 1px solid color.$brown-40;
            }

            & > div.mat-select-trigger {
              & > div.mat-select-arrow-wrapper {
                & > div.mat-select-arrow {
                  background-image: url('../../../assets/images/icons/chevron-up.svg');
                  filter: color.$filter-brown-40;
                }
              }
            }
          }

          &.mat-select-empty {
            & + span.mat-form-field-label-wrapper {
              display: inline-block;
            }
          }

          & > div.mat-select-trigger {
            padding-left: 8px;
            padding-right: 8px;

            & > div.mat-select-value {
              @include typography.meta-regular;
              color: color.$grey-70;
              font-size: 14px;
            }

            & > div.mat-select-arrow-wrapper {
              transform: translateX(100%);

              & > div.mat-select-arrow {
                width: 12px;
                height: 12px;
                margin: 0;
                background: url('../../../assets/images/icons/chevron-down.svg')
                  no-repeat center center;
                background-size: cover;
                border: 0;
                filter: color.$filter-grey-70;
              }
            }
          }
        }

        & > span.mat-form-field-label-wrapper {
          top: 0;
          display: none;
          padding-top: 0;

          & > label.mat-form-field-label {
            top: 50%;
            width: calc(100% - 18px);
            padding-left: 8px;
            padding-right: 8px;
            margin-top: 0;
            margin-bottom: 0;
            transform: translateY(-50%);

            & > mat-label {
              @include typography.meta-regular;
              color: color.$grey-40;
              font-size: 14px;
            }
          }
        }
      }
    }

    & > div.mat-form-field-subscript-wrapper {
      padding: 0;
      margin-top: 6px;
      position: relative;

      & > div.mat-form-field-hint-wrapper {
        & > mat-hint {
          @include typography.meta-regular;
          color: color.$grey-70;
          font-size: 10px;
        }
      }

      & mat-error.mat-error {
        @include typography.meta-regular;
        color: color.$red-50;
        font-size: 10px;
      }
    }
  }

  &.mat-form-field-disabled {
    & > div.mat-form-field-wrapper {
      & > div.mat-form-field-flex {
        & > div.mat-form-field-infix {
          & > mat-select {
            &:before {
              border: 1px solid color.$grey-40;
            }

            & > div.mat-select-trigger {
              & > div.mat-select-arrow-wrapper {
                & > div.mat-select-arrow {
                  filter: color.$filter-grey-40;
                }
              }
            }
          }
        }
      }
    }
  }

  &.mat-form-field-invalid {
    & > div.mat-form-field-wrapper {
      & > div.mat-form-field-flex {
        & > div.mat-form-field-infix {
          & > mat-select {
            &:before {
              border: 1px solid color.$red-50;
            }

            & > div.mat-select-trigger {
              & > div.mat-select-arrow-wrapper {
                & > div.mat-select-arrow {
                  filter: color.$red-50;
                }
              }
            }
          }
        }
      }
    }
  }

  &.full-width {
    width: 100%;
  }

  &.no-leading {
    & > div.mat-form-field-wrapper {
      padding: 0;
    }
  }
}

// Dropdown
div.cdk-overlay-pane {
  & > div.mat-select-panel-wrap {
    transform: translateY(47px);

    & > div.mat-select-panel {
      border: 1px solid color.$grey-30;
      border-top: 1px solid color.$brown-40;
      border-radius: 0;
      box-shadow: none;
      background-color: color.$white;

      & > mat-option {
        padding-left: 24px;
        padding-right: 24px;

        & > span.mat-option-text {
          @include typography.meta-regular;
          color: color.$grey-70;
          font-size: 14px;
        }

        &[aria-disabled='true'] {
          & > span.mat-option-text {
            color: color.$grey-40;
          }
        }

        &:not([aria-disabled='true']):hover {
          background: color.$grey-20;

          & > span.mat-option-text {
            color: color.$brown-50;
          }
        }

        &.mat-active {
          background: none;
        }
      }
    }
  }
}

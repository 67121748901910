@use '../../ui/color';
@use '../../ui/typography';

@mixin form-field-outline($color) {
  border: 1px solid $color;
  border-right: none;
}

@mixin form-field-outline-gap($color) {
  border: 1px solid $color;
  border-left: none;
  border-right: none;
}

@mixin form-field-outline-end($color) {
  border: 1px solid $color;
  border-left: none;
}

@mixin form-field-suffix-img($filter) {
  filter: $filter;
}

mat-form-field {
  &.mat-form-field-password,
  &.mat-form-field-type-mat-input {
    & > div.mat-form-field-wrapper {
      margin: 0;

      & > div.mat-form-field-flex {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0;
        margin-top: 0;
        background: color.$white;

        & > div.mat-form-field-outline {
          top: 0;

          & > div.mat-form-field-outline-start {
            @include form-field-outline(color.$grey-70);
            border-radius: 0;
          }

          & > div.mat-form-field-outline-gap {
            @include form-field-outline-gap(color.$grey-70);
            border-radius: 0;
          }

          & > div.mat-form-field-outline-end {
            @include form-field-outline-end(color.$grey-70);
            border-radius: 0;
          }
        }

        & > div.mat-form-field-infix {
          padding: 0;
          border: none;

          & > input,
          & > textarea {
            @include typography.meta-regular;
            color: color.$grey-70;
            font-size: 14px;
            padding: 0 24px;
            margin: 0;

            &::placeholder {
              color: color.$grey-40;
            }
          }

          & > input {
            line-height: 48px;
            width: calc(100% - 48px);
          }

          & > textarea {
            line-height: initial;
            width: calc(100% - 48px);
            height: 5em;
            padding: 1em 24px;
          }

          & > span.mat-form-field-label-wrapper {
            top: 0;
            padding-top: 0;
          }
        }

        & > div.mat-form-field-suffix {
          position: absolute;
          right: 1px;
          top: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 46px;
          height: 44px;
          background: color.$white;

          & > i {
            margin-right: 12px;

            & > img {
              @include form-field-suffix-img(color.$filter-grey-70);
            }

            &.button {
              cursor: pointer;
            }
          }

          & > span.mat-select-arrow {
            width: 24px;
            height: 24px;
            margin: 0;
            background: url('../../../assets/images/icons/chevron-down.svg')
              no-repeat center center;
            border: none;
            filter: color.$filter-grey-70;
          }
        }
      }

      & > div.mat-form-field-subscript-wrapper {
        padding: 0;
        margin-top: 6px;
        position: relative;

        & > div.mat-form-field-hint-wrapper {
          & > mat-hint {
            @include typography.meta-regular;
            color: color.$grey-70;
            font-size: 10px;
          }
        }

        & mat-error.mat-error {
          @include typography.meta-regular;
          color: color.$red-50;
          font-size: 10px;
        }
      }
    }

    &.mat-focused {
      & > div.mat-form-field-wrapper {
        & > div.mat-form-field-flex {
          & > div.mat-form-field-outline {
            & > div.mat-form-field-outline-start {
              @include form-field-outline(color.$brown-40);
            }

            & > div.mat-form-field-outline-gap {
              @include form-field-outline-gap(color.$brown-40);
            }

            & > div.mat-form-field-outline-end {
              @include form-field-outline-end(color.$brown-40);
            }
          }

          & > div.mat-form-field-suffix {
            & > i {
              & > img {
                @include form-field-suffix-img(color.$filter-brown-40);
              }
            }

            & > span.mat-select-arrow {
              filter: color.$filter-brown-50;
            }
          }
        }
      }
    }

    &.mat-form-field-disabled {
      & > div.mat-form-field-wrapper {
        & > div.mat-form-field-flex {
          & > div.mat-form-field-outline {
            & > div.mat-form-field-outline-start {
              @include form-field-outline(color.$grey-40);
            }

            & > div.mat-form-field-outline-gap {
              @include form-field-outline-gap(color.$grey-40);
            }

            & > div.mat-form-field-outline-end {
              @include form-field-outline-end(color.$grey-40);
            }
          }

          & > div.mat-form-field-suffix {
            & > i {
              & > img {
                @include form-field-suffix-img(color.$filter-grey-40);
              }
            }
          }
        }
      }
    }

    &.mat-form-field-invalid,
    &.custom-form-error-state-active {
      & > div.mat-form-field-wrapper {
        & > div.mat-form-field-flex {
          & > div.mat-form-field-outline {
            & > div.mat-form-field-outline-start {
              @include form-field-outline(color.$red-50);
            }

            & > div.mat-form-field-outline-gap {
              @include form-field-outline-gap(color.$red-50);
            }

            & > div.mat-form-field-outline-end {
              @include form-field-outline-end(color.$red-50);
            }
          }

          & > div.mat-form-field-suffix {
            & > i {
              & > img {
                @include form-field-suffix-img(color.$filter-red-50);
              }
            }
          }
        }

        & > div.mat-form-field-subscript-wrapper {
          & > div.mat-form-field-hint-wrapper {
            & > mat-hint {
              color: color.$red-50;
            }
          }
        }
      }
    }

    &.content-width {
      width: max-content;
    }

    &.no-leading {
      & > div.mat-form-field-wrapper {
        padding: 0;
      }
    }
  }
}
